<template>
  <div class="container">
    <div class="enroll-content">
      <div class="enroll-title">IP规划师证书申请</div>

      <el-form
        ref="form"
        :rules="rules"
        class="enroll-form"
        :model="form"
        label-position="top"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号：" prop="idCard">
              <el-input
                v-model="form.idCard"
                placeholder="请输入您的证件号码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="快递单号：" prop="expressNumber">
              <el-input
                v-model="form.expressNumber"
                placeholder="请输入您的快递单号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号：" prop="telephone">
              <el-input
                v-model="form.telephone"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="地址：" prop="region">
              <el-cascader
                size="large"
                :options="options"
                v-model="form.region"
                placeholder="请选择您的地址"
                @change="changeCity"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="dizhi" class="address" prop="address">
              <el-input
                v-model="form.address"
                placeholder="请输入您的详细地址（小区/门牌号）"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="附件一：" class="attachment" prop="fileUrlName1">
              <el-input class="file-upload" v-model="form.fileUrlName1">
                <el-button slot="append" icon="el-icon-upload">
                  <el-upload
                    class="file-uploader"
                    :action="config.qnuploadurl"
                    :show-file-list="false"
                    :data="{ token: qntoken }"
                    :before-upload="beforeFileUpload"
                    :on-success="uploadFile"
                  >
                  </el-upload>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="附件二：" class="attachment" prop="fileUrlName2">
              <el-input class="file-upload" v-model="form.fileUrlName2">
                <el-button slot="append" icon="el-icon-upload">
                  <el-upload
                    class="file-uploader"
                    :action="config.qnuploadurl"
                    :show-file-list="false"
                    :data="{ token: qntoken }"
                    :before-upload="beforeFileUpload2"
                    :on-success="uploadFile2"
                  >
                  </el-upload>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p class="prompt">请认真填写本表并连同照片一同寄回</p>
      <el-button type="primary" v-preventReClick @click="onSubmit">提交</el-button>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import { regionData } from "element-china-area-data";
import { idCardValidity,phoneRules,checkEmail } from "@/utils/rule.js"
export default {
  data() {
    return {
      config,
      qntoken: "",
      form: {
        name: "",
        examNumber:this.$route.query.examNumber,
        ipType:this.$route.query.ipType,
        idCard: "",
        telephone:"",
        expressNumber: "",
        provinceId: "",
        cityId: "",
        districtId: "",
        address: "",
        fileUrlName1:'',
        fileUrl1:"",
        fileUrlName2:"",
        fileUrl2:"",
        region: [],
      },
      options: regionData,
      rules: {
        name: [
          { required: true,message: "请输入您的姓名",trigger: "blur",}
        ],
        expressNumber: [
          {required: true,message: "请在此处输入快递单号",trigger: "blur",}
        ],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: phoneRules, trigger: ['change','blur'] }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: idCardValidity, trigger: ['change','blur'] }
        ],
        region: [
          {type:'array',required: true,message: "请点击选择所在省市区",trigger: 'change'}
        ],
				address:[
          { required: true, message: '请输入您的详细地址', trigger: 'change' }
        ],
				fileUrlName1:[
          { required: true, message: '请上传文件', trigger: 'change' }
        ],
        fileUrlName2:[
          { required: true, message: '请上传文件', trigger: 'change' }
        ],
      },
    };
  },
  created() {
    this.getQnToken();
  },
  methods: {
    getQnToken() {
      this.$store.dispatch("GetUploadToken").then((res) => {
        this.qntoken = res.data.qnToken;
      });
    },
    changeCity() {
      this.form.provinceId = this.form.region[0];
      this.form.cityId = this.form.region[1];
      this.form.districtId = this.form.region[2];
    },
    beforeFileUpload(file) {
      this.form.fileUrlName1 = file.name;
      return this.form;
    },
    uploadFile(res) {
      // console.log(res);
      this.form.fileUrl1 = config.qnurl + res.key;
    },
    beforeFileUpload2(file) {
      this.form.fileUrlName2 = file.name;
      return this.form;
    },
    uploadFile2(res) {
      // console.log(res);
      this.form.fileUrl2 = config.qnurl + res.key;
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
            .post("/api/web/exam/postAddIpTicket", this.form)
            .then(res => {
              this.$toast(res.msg);
              setTimeout(()=>{
                this.$router.push({
                  name: 'ResultsQuery',
                  params: {id: 1}
                })
              },1500)
            })
            .catch(err=> {
              this.$toast(err.msg);
            });
          
        } else {
          // this.$toast('请填写完整表单')
          return false;
        }
      });
      
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  padding: 30px 0;
  background-color: #f5f5f5;
  .enroll-content {
    width: 1300px;
    margin: 0 auto;
    background: #fff;
    padding: 30px 180px;
    .prompt {
      font-size: 14px;
      color: #d71a18;
      line-height: 32px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 30px;
    }
    .enroll-title {
      font-size: 24px;
      font-weight: 700;
      color: #333;
    }
    /deep/.enroll-steps {
      padding: 35px 0 30px;
      .el-step__title.is-process {
        font-weight: 500;
        color: #999;
      }
      .el-step__head.is-finish {
        .el-step__icon {
          color: #fff;
          background: #d71a18;
        }
        .el-step__line {
          border: 1px dashed #d71a18;
          background: none;
        }
      }
      .el-step__head.is-process {
        .el-step__icon {
          color: #fff;
          font-weight: 700;
          background: #e7e7e7;
          border-color: #e7e7e7;
        }
        .el-step__line {
          border: 1px dashed #e7e7e7;
          background: none;
        }
      }
      .el-step__head.is-wait {
        .el-step__icon {
          color: #fff;
          font-weight: 700;
          background: #e7e7e7;
          border-color: #e7e7e7;
        }
        .el-step__line {
          border: 1px dashed #e7e7e7;
          background: none;
        }
      }
    }
    /deep/.enroll-form {
      padding: 30px 0 0;
      .el-form-item {
        margin-bottom: 30px;
      }
      .el-select,
      .el-cascader {
        width: 100%;
      }
      .el-form-item__label {
        width: 100%;
        line-height: 1;
        color: #333;
        font-size: 16px;
        height: 36px;
        padding-bottom: 20px;
      }

      .el-input__inner {
        height: 50px;
        font-size: 16px;
      }

      .el-date-editor {
        width: 100%;
      }

      .attachment {
        margin-bottom: 10px;
      }

      .file-upload {
        .el-input-group__append {
          position: relative;
          background-color: #d71a18;

          .el-icon-upload {
            color: #ffffff;
          }
        }

        .file-uploader {
          position: absolute;
          left: 0;
          top: 0;
          width: 160px;
          height: 50px;

          .el-upload {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
		/deep/.address{
			.el-form-item__label{
				opacity: 0;
			}
		}
    .el-button {
      min-width: 160px;
      height: 50px;
      border-radius: 50px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
